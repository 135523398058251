.App-body {
  font-size: 1.6vw;
  font: Arial, Helvetica, sans-serif;
  height: 100vh;
  width: 100vw;
}

.about-page {
  width: 100%;
  height: 95%;
  font-size: min(2em, 16px);
  text-align: center;
  /* margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%; */
}
.about-page img {
  margin-top: 1%;
  height: auto;
  max-width: 60%;
}
.about-text {
  margin: 1% 20%;
}

.navigation-bar {
  position: sticky;
  height: 5%;
  width: 100%;
  z-index: 2;
  font-size: min(2em, 16px);
}
.navigation-bar a {
  color: rgba(255,255,255,.5);
}
.navigation-bar a:hover {
  color: rgba(255,255,255,.75);
  text-decoration: none;
}

.leaflet-container {
  width: 100%;
  height: 95%;
  z-index: 1;
}
.geoJSON:hover {
  stroke-width: 1.1em;
  transition: stroke-width .2s;
}

.map-controls {
  position: absolute;
  bottom: 2%;
  right: 1%;
  z-index: 2;
}

.legend {
  text-align: right;
  width: 100%;
  bottom: 2%;
  left: 1%;
  color: #555;
}
.legend i {
  width: 1.4em;
  height: 1.4em;
  float: left;
  margin-right: 1em;
}

.info {
  padding: .5em .8em;
  font-size: min(1.4em, 16px);
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 .3em rgba(0, 0, 0, 0.2);
  border-radius: .5em;
}
.info h4 {
  color: #777;
}